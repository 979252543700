import * as React from 'react';

import './AppLoader.css';

interface AppLoaderCircleProps {
    color?: string;
    background?: string;
}

export const AppLoaderCircle: React.FC<AppLoaderCircleProps> = (props) => {
    return (
        <div className="app-loader" style={{ display: 'none', backgroundColor: props.background }}>
            <div className="app-loader-circle">
                <span className="app-loader-animated-circle" style={props.color ? { color: props.color } : undefined}>
                    <svg viewBox="22 22 44 44">
                        <circle
                            cx="44"
                            cy="44"
                            r="20.2"
                            fill="none"
                            strokeWidth="3.6"
                            stroke="currentColor"
                            strokeDasharray="80px,200px"
                            strokeDashoffset="0"
                        ></circle>
                    </svg>
                </span>
            </div>
        </div>
    );
};