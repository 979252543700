// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.app-loader {
    position: fixed;
    z-index: 1300;
    right: 0;
    bottom: 0;
    top: 0;
    left: 0;
    display: block !important;
}

.app-loader-circle {
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

.app-loader-animated-circle {
    display: inline-block;
    color: #ECECEC;
    -webkit-animation: app-loader-animation 1.4s linear infinite;
    animation: app-loader-animation 1.4s linear infinite;
    width: 40px;
    height: 40px;
}

@keyframes app-loader-animation {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}`, "",{"version":3,"sources":["webpack://./src/submodules/egr-gui-elements/AppLoader/AppLoader.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,aAAa;IACb,QAAQ;IACR,SAAS;IACT,MAAM;IACN,OAAO;IACP,yBAAyB;AAC7B;;AAEA;IACI,kBAAkB;IAClB,SAAS;IACT,QAAQ;IACR,wCAAwC;IACxC,qCAAqC;IACrC,oCAAoC;IACpC,gCAAgC;AACpC;;AAEA;IACI,qBAAqB;IACrB,cAAc;IACd,4DAA4D;IAC5D,oDAAoD;IACpD,WAAW;IACX,YAAY;AAChB;;AAEA;IACI;QACI,uBAAuB;IAC3B;IACA;QACI,yBAAyB;IAC7B;AACJ","sourcesContent":[".app-loader {\n    position: fixed;\n    z-index: 1300;\n    right: 0;\n    bottom: 0;\n    top: 0;\n    left: 0;\n    display: block !important;\n}\n\n.app-loader-circle {\n    position: absolute;\n    left: 50%;\n    top: 50%;\n    -webkit-transform: translate(-50%, -50%);\n    -moz-transform: translate(-50%, -50%);\n    -ms-transform: translate(-50%, -50%);\n    transform: translate(-50%, -50%);\n}\n\n.app-loader-animated-circle {\n    display: inline-block;\n    color: #ECECEC;\n    -webkit-animation: app-loader-animation 1.4s linear infinite;\n    animation: app-loader-animation 1.4s linear infinite;\n    width: 40px;\n    height: 40px;\n}\n\n@keyframes app-loader-animation {\n    0% {\n        transform: rotate(0deg);\n    }\n    100% {\n        transform: rotate(360deg);\n    }\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
